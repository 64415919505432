$(document).ready(function () {

	$('#toTop, #backtotop').fadeOut(0);

	$(window).scroll(function () {
		if ($(this).scrollTop() != 0) {
			$('#toTop, #backtotop').fadeIn();
			$('#toTop, #logowater').fadeIn();
		} else {
			$('#toTop, #backtotop').fadeOut();
			$('#toTop, #logowater').fadeOut();
		}
	});
	$('#toTop').click(function () {
		$('body,html').animate({ scrollTop: 0 }, 500);
	});
});